import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import timeline from "../../db/timeline.json";
import "react-vertical-timeline-component/style.min.css";
import "./Timeline.scss";

const TimeLine = () => {
  const timelineData = [...timeline.data];

  return (
    <VerticalTimeline key={timelineData.year}>
      {timelineData.map((data, index) => {
        index += 1;
        return (
          <VerticalTimelineElement
            key={data.year}
            className={
              index % 2 === 0
                ? "vertical-timeline-element--education"
                : "vertical-timeline-element--work odd"
            }
            date={data.year}
            position={index % 2 === 0 ? "right" : "left"}
            iconStyle={
              index % 2 === 0
                ? { background: "rgb(233, 30, 99)", color: "#fff" }
                : { background: "rgb(33, 150, 243)", color: "#fff" }
            }
          >
            <h5 className="vertical-timeline-element-title mb-3 fw-bold">
              {data.title}
            </h5>
            <h6 className="vertical-timeline-element-subtitle fw-bold">
              {data.location}
            </h6>
            <p>{data.description}</p>
          </VerticalTimelineElement>
        );
      })}
    </VerticalTimeline>
  );
};

export default TimeLine;
