import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { debounce } from "lodash/";
import productDetailList from "../../../db/product-list.json";
import ProductFinder from "../ProductFinder";
import ProductThumbnail from "./ProductThumbnail";
import "./index.scss";

const ProductList = () => {
  const [searchInputText, setSearchInputText] = useState("");
  const [filteredProductList, setFilteredProductList] = useState([
    ...productDetailList.data,
  ]);

  const { type } = useParams();

  useEffect(() => {
    if (type !== "all") {
      setFilteredProductList((prevList) =>
        prevList.filter((product) =>
          product.type.toLowerCase().includes(type.toLowerCase())
        )
      );
    }
  }, [type]);

  const filterProductListBySearchText = debounce((query) => {
    if (query) {
      setSearchInputText(query);
      setFilteredProductList((prevList) =>
        prevList.filter((product) =>
          product.series.toLowerCase().includes(query)
        )
      );
    } else {
      setSearchInputText("");
      setFilteredProductList([...productDetailList.data]);
    }
  }, 500);

  return (
    <>
      {type === "all" ? (
        <ProductFinder
          onProductSearch={filterProductListBySearchText}
          searchInputText={searchInputText}
        />
      ) : null}
      <div className="row justify-content-center align-items-center search-results">
        {filteredProductList.length ? (
          filteredProductList.map((product, index) => {
            return (
              <ProductThumbnail
                key={`${product.series}-${index}`}
                product={product}
              />
            );
          })
        ) : (
          <strong className="fs-26 text-center">No product found.</strong>
        )}
      </div>
    </>
  );
};

export default ProductList;
