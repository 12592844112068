import "./index.scss";

const Banner = (props) => {
  return (
    <div
      className={`row text-center justify-content-center align-items-center banner ${props.backgroundImage}`}
    >
      <div className="col-12">
        <h1 className="white">{props.title}</h1>
        <h2 className="white">{props.subtitle}</h2>
      </div>
    </div>
  );
};

export default Banner;
