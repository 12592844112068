// import Form from "../../components/Form";
import Banner from "../../components/Banner";
import Address from "../../components/Address";
import Form from "./_Form";
import "./index.scss";

const Contact = () => {
  return (
    <div id="contact-page">
      <Banner
        title="Contact Us"
        subtitle={`"Our professional team of customer care is ready to hear from you"`}
        backgroundImage="contact-banner"
      />
      <div className="row justify-content-center align-items-center mt-5">
        <div className="col-12 col-md-6 col-lg-4">
          <Form />
        </div>
      </div>
      <div className="row office-section justify-content-center mt-5">
        <Address />
      </div>
    </div>
  );
};

export default Contact;
