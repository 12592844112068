import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./views/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Product from "./views/Product";
import ProductList from "./views/Product/ProductList";
import Facility from "./views/Facility";
import Contact from "./views/Contact";
import "./index.scss";

const App = () => {
  const routes = (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/product" exact element={<Product />} />
      <Route path="/product/:type" exact element={<ProductList />} />
      <Route path="/facility" exact element={<Facility />} />
      <Route path="/contact" exact element={<Contact />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );

  return (
    <div className="App">
      <div className="container-fluid">
        <Header />
        <main>{routes}</main>
        <Footer />
      </div>
    </div>
  );
};

export default App;
