import Banner from "../../components/Banner";
import ImageGallery from "react-image-gallery";
import facilityGalleries from "../../db/facility.json";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "./index.scss";

export const Facility = () => {
  const facilityGalleriesData = [...facilityGalleries.data];

  return (
    <div id="facility-page" className="text-center">
      <Banner
        title="Welcome to Elcon Facility Galleries"
        subtitle=""
        backgroundImage="facility-banner"
      />

      <div className="row justify-content-center align-items-center pb-5">
        <div className="col-12">
          <h2 className="mt-5 mb-5">
            <span className="main-green">*</span>
            Facility Equipments
            <span className="main-green">*</span>
          </h2>
        </div>
        <div className="col-12 col-md-8 col-lg-6">
          <ImageGallery items={facilityGalleriesData} />
        </div>
      </div>
    </div>
  );
};

export default Facility;
