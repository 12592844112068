import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import "./_Form.scss";

const ContactForm = () => {
  const {
    register,
    formState: { errors, dirtyFields, isValid, isDirty },
  } = useForm();

  return (
    <Form
      id="contact-form"
      action="https://formspree.io/info@elcon-fsd.com"
      method="POST"
      noValidate
    >
      <Form.Group className="mb-3">
        <Form.Control
          type="text"
          placeholder="Name"
          {...register("name", { required: true, maxLength: 20 })}
        />
        {errors.name && dirtyFields.name && (
          <Form.Control.Feedback type="invalid" className="d-block">
            Please provide a valid name
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Control
          type="text"
          placeholder="Company"
          {...register("company", { required: true })}
        />
        {errors.company && dirtyFields.company && (
          <Form.Control.Feedback type="invalid" className="d-block">
            Please provide a valid company name
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Control
          type="email"
          placeholder="Email"
          {...register("email", {
            required: true,
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          })}
        />
        {errors.email && dirtyFields.email && (
          <Form.Control.Feedback type="invalid" className="d-block">
            Please provide a valid email address
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Control
          as="textarea"
          type="text"
          cols="30"
          rows="10"
          placeholder="Message"
          {...register("message", { required: true })}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        disabled={!isDirty || !isValid}
        className={`btn btn-lg send-btn ${isValid ? "" : "disabled"}`}
      >
        Send
      </Button>
    </Form>
  );
};

export default ContactForm;
