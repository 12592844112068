import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Navigation.scss";

const Navigation = () => {
  return (
    <div className="row">
      <div className="col-12 ps-0 pe-0">
        <Navbar collapseOnSelect expand="xl" className="p-3">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav
              className="
            text-center 
            w-100 
            fw-bold
            d-flex 
            justify-content-center 
            justify-content-lg-evenly 
            align-items-center"
            >
              <Link to="/">Home</Link>
              <Link to="/product">Product</Link>
              <Link to="/facility">Facility</Link>
              <Link to="/contact">Contact</Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Navigation;
