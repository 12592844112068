import { InputGroup, Form } from "react-bootstrap";
import "./index.scss";

const ProductFinder = (props) => {
  return (
    <div
      className="row text-center justify-content-center align-items-center"
      id="product-finder"
    >
      <div className="col-12">
        <h1>Product Finder</h1>
        <h4 className="d-none d-md-block">
          Faster Product Search With Just Series Code
        </h4>
        <InputGroup className="search-input" id="product-search">
          <InputGroup.Text id="product-search">
            <i className="fa fa-search search-icon"></i>
          </InputGroup.Text>
          <Form.Control
            placeholder="Find product with series number"
            aria-label="Product Finder"
            aria-describedby="product-search"
            onChange={(event) =>
              props.onProductSearch(event.target.value.toLowerCase())
            }
          />
        </InputGroup>
      </div>
    </div>
  );
};

export default ProductFinder;
