import Button from "../Button";
import "./index.scss";

const CallOutPanel = (props) => {
  return (
    <div className="text-box d-none d-md-block">
      <p className="mb-4">{props.description}</p>
      <Button
        btnText={props.btnText}
        link={props.link}
        btnClass={props.btnClass}
      />
    </div>
  );
};

export default CallOutPanel;
